import { CompanyInfo, useCurrentSessionContext } from '@/context/currentSessionContext';
import { useGetUserFromCurrentSession } from '@/hooks/useGetUserFromCurrentSession';
import { CurrentUserResponse, User } from '@/services/get-current-user/getCurrentUserService';
import { signIn, useSession } from 'next-auth/react';
import React, { memo, useEffect, useState, useCallback, useMemo } from 'react';
import UIButton from '../buttons/UIButton';
import { Box, Button, Grid, useMediaQuery } from '@mui/material';
import { capitalizeFirstLetter, isEmpty } from '@/utils/functions';
import UIModal from "@/components/modal/UIModal";
import { textStyle } from '../modal/UIModalProps';
import ASIRoles from '@/utils/roles';
import UILoading from '../loading/UILoading';
import { useModal } from 'mui-modal-provider';
import UIConfirmationDialog from '../dialogs/UIConfirmationDialog';
import { enqueueSnackbar } from 'notistack';
import { useGetAgentPermissions } from '@/hooks/useGetAgentPermissions';

interface UIPrivateLayoutProps {
  children: React.ReactNode;
}

const isSpecialPermission = (login: string) => {
  return ['LBAEZA','RDIAZ'].includes(login.toUpperCase());
}

const UIPrivateLayout: React.FC<UIPrivateLayoutProps> = ({ children }) => {
  const getUserFromCurrentSession = useGetUserFromCurrentSession();
  const activeAuth = process.env.NEXT_PUBLIC_ACTIVATE_AUTH === 'true';
  const { data, status } = useSession();
  const { setSession, session } = useCurrentSessionContext();
  const [startLogin, setStartLogin] = useState(false);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:750px)');
  const [validateCertificated, setValidateCertificated] = useState(false);

  const getPermissions = useGetAgentPermissions();

  const closeModal = () => {
    setOpenModal(false);
  };

  const handleStartLogin = useCallback(() => {
    setSession(undefined);
    setStartLogin(true);
    signIn();
  }, [setSession]);
  const memoizedChildren = useMemo(() => children, [children]);


  const isValidData = useCallback((data: any): data is { user: User } => {
    return data && data.user;
  }, []);

  const modal = <UIModal
    open={openModal}
    onClose={closeModal}
    noClose={true}
    logo="/assets/icons/face-sad.svg"
    body={
      <>
        <h1 style={{ ...textStyle.modalTitle, marginTop: "-20px" }}>No podrás continuar</h1>
        <p style={{ ...textStyle.modalMessage, textAlign: "justify", marginTop: "10px" }}>El usuario actual corresponde a un perfil con el que actualmente no es posible realizar tu alta; estamos trabajando en la solución.</p>
      </>
    }
    footer={
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <UIButton
            color="orange"
            label="Cerrar sesión"
            onClick={closeModal}
            fullWidth
          />
        </Grid>

      </Grid>
    }
    size={isSmallScreen ? { width: '90%', height: '300px' } : { width: '430px', height: '300px' }}
  />;

  const expiredCertificate = useCallback((userCompanies:CurrentUserResponse[]) => {
    if(validateCertificated) return;
    
    const firmasDigitales = userCompanies.filter((user) => user.firmaDigital === "Y");
    if(firmasDigitales.length === 0) {
      setValidateCertificated(true);
      return;
    }

    firmasDigitales.forEach((user) => {
      if (!user.fechaCaduca ||user.fechaCaduca === "" ||new Date(user.fechaCaduca) < new Date()){
        enqueueSnackbar(`La firma digital de ${user.empresa} a expirado`, { variant: 'error' });
      }
    });
    setValidateCertificated(true);
  }, [validateCertificated]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {

        if (!session || session?.user || isUserLoading || (activeAuth && !data)) return;
        
        const user = await getUserFromCurrentSession();
        const [first] = user;
        const idpUser = data?.user as User;
        console.log('idpUser', idpUser);
        setIsUserLoading(true);
        expiredCertificate(user);
        const getCompany = () =>{
          const comp:Array<CompanyInfo> = [];
          for (let index = 0; index < user.length; index++) {
            const element = user[index];
            const item = comp.find(item => item.name.toUpperCase() == element.empresa.toUpperCase());
            if(item){
              item.subsidiary.push({
                subsidiary: element.idNombreSucursal,
                subsidiaryId: element.idSucursal,
                agentId: element.rowId,
                agentPosition: element.positionId,
                agentPositionName: element.positionName,
                firmaDigital: element.firmaDigital,
                corredorId: element.agenteCorredorId,
                isDefault: element.primaryPos === 'Y',
                agentNumber: element.agenteNum ?? element.corredorNum,
              })
            }else{
              comp.push({
                name: capitalizeFirstLetter(element.empresa),
                rol: element.rol,
                subsidiary:[
                  {
                    subsidiary: element.idNombreSucursal,
                    subsidiaryId: element.idSucursal,
                    agentId: element.rowId,
                    agentPosition: element.positionId,
                    agentPositionName: element.positionName,
                    firmaDigital: element.firmaDigital,
                    corredorId: element.agenteCorredorId,
                    isDefault: element.primaryPos === 'Y',
                    agentNumber: element.agenteNum ?? element.corredorNum,                  
                  }
                ]
              })
            }
          }
          return comp;
        };
        setSession(prev => {
          const hasUserChanged = prev?.user?.login !== first.login;
          const hasCompanyChanged = prev?.company?.length !== user.length;       

          if(process.env.IS_PROD === 'true'){
            const hardCodeAgentName = "";
            if (hasUserChanged || hasCompanyChanged) {
              //const _userName = ( isEmpty(first.nombreUsuario) ? hardCodeAgentName : first.nombreUsuario);
              const agentName = () => {
                const perm = isSpecialPermission(first.login);
                if(perm){
                    return 'IVONNE PADILLA MOLINA';
                }

                if (first.agenteCorredorTipo.toUpperCase().includes("MORAL")) {
                  return isEmpty(first.nombreCorredor) ? hardCodeAgentName: first.nombreCorredor;
                } else {
                  return isEmpty(first.nombreAgente) ? hardCodeAgentName : first.nombreAgente;
                }
              };
              let email = "";
              if (idpUser?.attributes?.PrimaryEmail) {
                email = isEmpty(idpUser.attributes.PrimaryEmail[0]) ? "" : idpUser.attributes.PrimaryEmail[0]
              }
              return {
                user: {
                  login: first.login,
                  email,
                  username: isEmpty(first.nombreAgente) ? hardCodeAgentName : first.nombreAgente,
                  agentId: isEmpty(first.agenteCorredorId) ? '' : first.agenteCorredorId,
                  agentType: isEmpty(first.agenteCorredorTipo) ? "Física" : first.agenteCorredorTipo,
                  agentName: agentName(),
                  //role: "Emp_Suc",
                  role: isEmpty(first.rol) ? "" : first.rol,
                  raw: user,                  
                },
                company: getCompany(),
                userRaw: idpUser,
              };
            }
          }else{
            if (hasUserChanged || hasCompanyChanged) {
              const agentName = () => {
                if (first.agenteCorredorTipo.toUpperCase().includes("MORAL")) {
                  return isEmpty(first.nombreCorredor) ? isEmpty(first.nombreUsuario) ? "EDITH LUGO RAMIREZ" : first.nombreUsuario : first.nombreCorredor;
                } else {
                  return isEmpty(first.nombreAgente) ? isEmpty(first.nombreUsuario) ? "EDITH LUGO RAMIREZ" : first.nombreUsuario : first.nombreAgente;
                }
              };

              let email = "";
              if (idpUser?.attributes?.PrimaryEmail) {
                email = isEmpty(idpUser?.attributes?.PrimaryEmail[0]) ? "c.trujillo.rdz95@gmail.com" : idpUser.attributes.PrimaryEmail[0];
              }

              return {
                user: {
                  login: first.login,
                  email,
                  username: isEmpty(first.nombreAgente) ? "EDITH LUGO RAMIREZ" : first.nombreAgente,
                  agentId: isEmpty(first.agenteCorredorId) ? '1-1IRMN' : first.agenteCorredorId,
                  agentType: isEmpty(first.agenteCorredorTipo) ? "Física" : first.agenteCorredorTipo,
                  agentName: agentName(),
                  role: isEmpty(first.rol) ? "Agente" : first.rol,
                  raw: user,                  
                },
                company: getCompany(),
                userRaw: idpUser,
              };
            }
          }

          return prev;
        });
        fetchUserPermissions();
      } catch (error) {
        console.log(error);
      }
    };

    const fetchUserPermissions = async () => { 
      try{
        const user = await getUserFromCurrentSession();
        const [first] = user;
        const permissions = await getPermissions({ agenteId: first.login });  
        let permissionsArray: string[] = [];
        if(permissions){
          if('moduloEmision' in permissions && permissions.moduloEmision){
            permissionsArray.push('emision');
          }
          if('moduloEstadosFinancieros' in permissions && permissions.moduloEstadosFinancieros){
            permissionsArray.push('estadosFinancieros');
          }
        }
        setSession(prev => {
          return {
            ...prev,
            permissions: permissionsArray
          }
        });
  
      }catch(error){
        console.log("Error al cargar permisos", error);
      }
    };

    if (activeAuth && isValidData(data) && status === 'authenticated' && !isUserLoading) {
      fetchUserData();
    } else if (!activeAuth) {
      if (process.env.IS_PROD !== 'true') {
        fetchUserData();
      }
    }
  }, [activeAuth, data, getUserFromCurrentSession, isUserLoading, session, setSession, status]);

  if (status === 'loading') {
    return <UILoading/>;
  }
  if (activeAuth && (!data || !session)) {
    return (
      <>
        {startLogin && <div>Redirigiendo a la página de inicio de sesión...</div>}
        {!startLogin && (
          <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <UIButton fullWidth={false} onClick={handleStartLogin} label='Iniciar sesión' color="orange" />
          </Box>
        )}
      </>
    );
  }
  if (activeAuth && (status === "unauthenticated" || (!session || !session.user))) return <UILoading/>;
  if (activeAuth && ((session && session.user && isEmpty(session.user.agentName))) && (session.user.role !== ASIRoles.Suc && session.user.role !== ASIRoles.Sucursal)) {
    return<>
    <UILoading/>
    { openModal && modal }
    </>;
  }


  return <>
  {/* <div>
    <atom-plugin-web id="luv3Ki0btuTL6wgjlnp7" company="oiIR8mRAAlkNLkgp40I4" style={{marginBottom: '150px'}}  customchat='["position: bottom-left", "margin-bottom: 150px"]'></atom-plugin-web>
  </div> */}
  {memoizedChildren}
  </>;
};

export default memo(UIPrivateLayout);
