import { SessionInfo } from "@/context/currentSessionContext";

enum ASIRoles {
    Suc = 'Emp_Suc',
    Agente = 'Agente',
    EmpAgente = 'Emp_Agente',
    Promotor = 'Promotor',
    Colaborador = 'Colaborador',
    Sucursal = 'Emp_Sucursal',
    Promovido = 'Promovido'
}


const sucursalType = [ASIRoles.Suc.toUpperCase(), ASIRoles.Sucursal.toUpperCase()];
const agenteType = [ASIRoles.EmpAgente.toUpperCase(), ASIRoles.Agente.toUpperCase()];
const promotorType = [ASIRoles.Promotor.toUpperCase()];
const promovidoType = [ASIRoles.Promovido.toUpperCase()];

const isPromovido = (role?: string) => {
    if (!role) return false;
    return promovidoType.includes(role.toUpperCase());
}
const isSucursal = (role?: string) => {
    if (!role) return false;
    return sucursalType.includes(role.toUpperCase());
}

const isPromotor = (role?: string) => {
    if (!role) return false;
    return promotorType.includes(role.toUpperCase());
}

const isAgent = (role?: string) => {
    if (!role) return false;
    return agenteType.includes(role.toUpperCase());
}
const isRoleSucOrPromotor = (session?: SessionInfo) =>{
    const _isSucursal = isSucursal(session?.user?.role?.toUpperCase());
    const _isPromotor = isPromotor(session?.user?.role?.toUpperCase());
    return (_isSucursal || _isPromotor);
}

const isRoleSucPromotorOrAgent = (session?: SessionInfo) =>{
    const _isSucursal = isSucursal(session?.user?.role?.toUpperCase());
    const _isPromotor = isPromotor(session?.user?.role?.toUpperCase());
    const _isAgent = isAgent(session?.user?.role?.toUpperCase());
    const _isPromovido = isPromovido(session?.user?.role?.toUpperCase());
    return (_isAgent || _isPromotor || _isSucursal || _isPromovido);
}


export default ASIRoles;
export { isSucursal, isPromotor , isRoleSucOrPromotor, isRoleSucPromotorOrAgent, isAgent };